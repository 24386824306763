import { BaseService } from './baseService'
import apis from './apis'

export class PricesService extends BaseService {
  static async getAdminPrices(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.admin_get_prices,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async adminCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.admin_prices_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async adminUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.admin_prices_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async customerCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.customer_prices_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async customerUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.customer_prices_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getCustomerPrices(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.customer_get_detail_price}/${id}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getGuestPrices(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.customer_get_prices,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async shopCreate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.shop_prices_create,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async shopUpdate(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.shop_prices_update,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getShopPrices(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.shop_get_prices,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
  static async getPriceShop() {
    try {
      const response = await this.request({ auth: true }).get(
        apis.get_prices_group
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
  static async getAdminPricesGroup(partnerId) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.get_admin_prices_group}/${partnerId}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getDetailPriceGroup(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.get_detail_price_group}/${id}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getPoPrices(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.po_price,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async poCreatePrice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.po_create_price,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getDetailPoPrice(id) {
    try {
      const response = await this.request({ auth: true }).get(
        `${apis.po_detail_price}/${id}`
      );
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async poUpdatePrice(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.po_update_price,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }

  static async getAccountNotAssign(pars) {
    try {
      const response = await this.request({ auth: true }).post(
        apis.get_account_not_assign,
        pars
      )
      return this.handleResponse(response)
    } catch (error) {
      return this.handleResponse(error.response)
    }
  }
}
